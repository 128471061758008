<template>
  <va-inner-loading :loading="loading" icon="restart_alt">
    <!-- Breadcrumb  -->
    <va-card class="row align--center mx-1">
      <va-card-content>
        <va-breadcrumbs color="primary">
          <va-breadcrumbs-item :label="$t('championships')" />
          <va-breadcrumbs-item :label="$t('upcoming')" />
        </va-breadcrumbs>
      </va-card-content>
    </va-card>
    <!-- Content  -->
    <div class="mt-4">
      <va-card v-if="upcomingChamps.length > 0" class="va-table-responsive p-3">
        <va-card-content>
          <table class="va-table va-table--hoverable w-100">
            <thead>
              <tr>
                <th>{{ $t("id") }}</th>
                <th>{{ $t("organizer") }}</th>
                <th>{{ $t("championship_name") }}</th>
                <th>{{ $t("teams_count") }}</th>
                <th>{{ $t("start_time") }}</th>
                <th>{{ $t("view_more") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                :style="
                  new Date(champ.suggested_start_time) <= Today
                    ? 'background-color : beige;'
                    : ''
                "
                v-for="(champ, index) in upcomingChamps"
                :key="champ.id"
              >
                <td>{{ index + offset + 1 }}</td>
                <td>{{ champ.creator.name }}</td>
                <td>{{ champ.name }}</td>
                <td>{{ champ.teams_count }}</td>
                <td>{{ champ.suggested_start_time }}</td>
                <td>
                  <va-button
                    color="info"
                    flat
                    icon="eye"
                    @click="ViewUpcomingChampDetails(champ)"
                  ></va-button>
                </td>
              </tr>
            </tbody>
          </table>
          <va-pagination
            v-if="pages > 1"
            color="#0a4695"
            style="direction: ltr"
            class="mt-5"
            v-model="currentPage"
            :visible-pages="3"
            :pages="pages"
          />
        </va-card-content>
      </va-card>
      <div class="mx-3" v-else>
        <va-card
          class="row align--center"
          style="height: 35vh"
          stripe
          stripe-color="#1f1f62"
        >
          <va-card-content>
            <h1 class="text-center" style="font-size: 32px">
              {{ $t("no_upcoming") }}
              <va-icon class="material-icons" size="32px"> schedule </va-icon>
            </h1>
          </va-card-content>
        </va-card>
      </div>
    </div>
  </va-inner-loading>
</template>

<script>
import { request, gql } from "graphql-request";

export default {
  data() {
    return {
      upcomingChamps: [],
      upcomingChampsCount: null,
      pages: null,
      currentPage: 1,
      limit: 10,
      offset: 0,
      loading: false,
      Today: new Date(),
    };
  },
  async mounted() {
    // Pagination Logic
    this.Today.setDate(this.Today.getDate() - 1);
    try {
      this.loading = true;
      const UPCOMING_CHAMPIONSHIPS_COUNT = gql`
        query {
          ChampsCount(where: { actual_start_time: null, finish_time: null }) {
            count
          }
        }
      `;
      const counterResponse = await request(
        this.$store.state.appUrl,
        UPCOMING_CHAMPIONSHIPS_COUNT
      );
      this.upcomingChampsCount = counterResponse.ChampsCount.count;
      this.pages = Math.ceil(this.upcomingChampsCount / this.limit);
      this.getUpcomingChampionships();
    } catch (error) {
      this.loading = false;
      this.$swal
        .fire({
          icon: "warning",
          title: this.$t("errors.network"),
          text: "Try Again in a minute",
          confirmButtonColor: "#023b81",
          confirmButtonText: "Reload",
        })
        .then((result) => {
          if ((result.value = true)) {
            location.reload();
          }
        });
    }
  },
  methods: {
    async getUpcomingChampionships() {
      this.loading = true;
      this.offset = (this.currentPage - 1) * this.limit;
      const UPCOMING_CHAMPIONSHIPS = gql`
        query ($limit: Int, $offset: Int) {
          Champs(
            where: { actual_start_time: null, finish_time: null }
            limit: $limit
            offset: $offset
            order: "reverse:suggested_start_time"
          ) {
            id
            name
            logo
            teams_count
            team_players_count
            payment_time
            suggested_start_time
            has_referees
            champ_type {
              name
              name_ar
            }
            creator {
              name
            }
            created
            updated
          }
        }
      `;
      try {
        const response = await request(
          this.$store.state.appUrl,
          UPCOMING_CHAMPIONSHIPS,
          { limit: this.limit, offset: this.offset }
        );
        this.upcomingChamps = response.Champs;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.$swal
          .fire({
            icon: "warning",
            title: this.$t("errors.network"),
            text: "Try Again in a minute",
            confirmButtonColor: "#023b81",
            confirmButtonText: "Reload",
          })
          .then((result) => {
            if ((result.value = true)) {
              location.reload();
            }
          });
      }
    },
    ViewUpcomingChampDetails(championship) {
      localStorage["routeName"] = this.$route.name;
      this.$router.push({
        name: "championshipDashboard",
        params: { id: championship.id, route: this.$route.name },
      });
    },
  },
  computed: {},
  watch: {
    async currentPage() {
      await this.getUpcomingChampionships();
    },
  },
};
</script>
